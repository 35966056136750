.bodymain {
  word-break: break-word;
  font-family: "Rubik", "Roboto", sans-serif;
  font-size: 1rem;
  color: #666;
  line-height: 1.525em;
  font-weight: 400;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}
.ql-editor {
  word-break: break-word;
  font-family: "Rubik", "Roboto", sans-serif;
  font-size: 1rem;
  color: #666;
  line-height: 1.525em;
  font-weight: 400;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
img {
  width: 100%;
  height: auto;
  image-rendering: auto;
  margin-top: 15px;
  border-radius: 6px;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
    word-break: normal;
  color: black;
  font-weight: 400;
}

::selection {
  color: white;
  background: #754668; /* WebKit/Blink Browsers */
}
::-moz-selection {
  color: white;
  background: #754668; /* Gecko Browsers */
}

.sh-section {
  padding: 15px 15px 0;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
@media only screen and (min-width: 768px) {
  .sh-section {
    padding: 15px 25px 0;
  }
}
.sh-section-menu {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.sh-section__head {
  padding-bottom: 15px;
  align-items: center;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .sh-section__head {
    padding-bottom: 20px;
  }
}
.sh-section__avatar {
  margin-right: 30px;
}
.sh-avatar {
  display: block;
  background-color: #ffffff;
  border-radius: 6px;
  min-width: 38px;
  width: 38px;
  height: 38px;
  margin-right: 30px;
}
.sh-avatar img {
  margin: 0;
}
.sh-section__name {
  display: block;
  font-weight: 500;
  color: #754668;
}
.sh-section__btn-upvote,
.sh-section__btn-downvote,
.sh-section__btn-repost,
.sh-section__btn-like,
.sh-section__btn-comment {
  margin-right: 20px;
}
.sh-section__btn-options {
  padding-left: 15px;
  padding-right: 5px;
  margin-left: auto;
}
.sh-section__btn-cancel {
  float: right;
}
.sh-section__btn-like span,
.sh-section__btn-comment span {
  margin-left: 15px;
}
.sh-section__btn-share {
  margin-left: auto;
}
.sh-section__name:visited,
.sh-section__name:active,
.sh-section__name:link {
  color: #754668;
}
.sh-section__passed {
  color: #9fa3ac;
}

.sh-btn-icon {
  align-items: center;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.sh-btn-icon > i,
.sh-btn-icon > span {
  color: #a1a5ae;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.sh-btn-icon > i {
  font-size: 1.2rem;
}
.sh-btn-icon.active > i,
.sh-btn-icon:hover > i,
.sh-btn-icon.active > span,
.sh-btn-icon:hover > span {
  color: #754668;
}
.sh-section__content {
  border-bottom: solid 1px #e6e9f1;
  padding-bottom: 15px;
}
@media only screen and (min-width: 768px) {
  .sh-section__content {
    padding-bottom: 20px;
  }
}
.sh-section__text {
  margin: 0;
  color: black;
  font-size: 1.3rem;
  font-weight: 400;
}

.sh-section__footer {
  padding-top: 5px;
  padding-bottom: 15px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  .sh-section__footer {
    padding-bottom: 20px;
    justify-content: unset;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}

.sh-feeling {
  padding: 15px 15px 15px 15px;
  justify-content: space-around;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0 -15px;
  background-color: #8c32738c;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
@media only screen and (min-width: 768px) {
  .sh-feeling {
    margin: 0 -25px;
  }
}
.sh-feeling a {
  width: 48px;
  margin-right: 8px;
  text-align: center;
}
.sh-feeling a span {
  display: inline-block;
  margin-top: 6px;
  color: white;
}
.sh-add-comment {
  padding: 15px 15px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 6px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
@media only screen and (min-width: 768px) {
  .sh-add-comment {
    padding: 25px 25px;
  }
}
.sh-add-comment form input.form-control {
  padding: 0;
}
.sh-add-comment__content {
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
  flex-wrap: wrap;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.sh-add-comment__content > span {
  color: #9fa3ac;
}
.sh-add-comment__btn-addimg {
  margin-right: 15px;
}
.sh-add-comment__btn-save {
  margin-left: auto;
}
.sh-add-comment__btn-save span {
  margin-right: 15px;
}
.sh-add-comment__btn-save i {
  font-size: 20px;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control::-moz-placeholder {
  color: #999999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999999;
}
.form-control::-webkit-input-placeholder {
  color: #999999;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
  opacity: 1;
}
textarea.form-control {
  height: auto;
}
.ttg-border-none.form-control {
  padding: 0;
  padding-right: 26px;
}
.ttg-border-none.form-control,
.ttg-border-none.form-control:focus {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sh-comments {
  padding-bottom: 15px;
}
@media only screen and (min-width: 768px) {
  .sh-comments {
    padding-bottom: 30px;
  }
}
.sh-comments__head {
  padding-bottom: 20px;
  border-bottom: solid 1px #e6e9f1;
  flex-wrap: wrap;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.sh-comments__title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  color: black;
}
.sh-comments__options {
  margin-left: auto;
  align-items: center;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  color: black;
}
.sh-comments__options span {
  margin-right: 30px;
}
.sh-comments__options {
  margin-right: 30px;
}
.sh-comments__section {
  margin-top: 30px;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.sh-comments__section p {
  margin-top: 15px;
}
.sh-comments__content {
  width: 100%;
}
.sh-comments__content > a > img {
  max-width: 266px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.sh-comments__name {
  white-space: pre;
  font-weight: 500;
}
.sh-comments__name:visited,
.sh-comments__name:active,
.sh-comments__name:link {
  color: #754668;
}
.sh-comments__passed {
  color: #9fa3ac;
}
.sh-comments__info {
  display: flex;
  margin-top: 5px;
}
.sh-comments__info {
  margin-right: 10px;
  margin-top: 10px;
}
.sh-comments__info {
  margin-left: 5px;
}
.sh-comments__btn-reply {
  display: block;
  float: right;
}
.sh-content-head {
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.sh-content-head-right {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 8px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.sh-content-head__flex-off {
  display: block;
}
.sh-content-head__btn-prev i {
  margin-right: 30px;
}
.sh-content-head__btn-next span {
  margin-right: 30px;
}
/* @media only screen and (min-width: 1025px) {
  .sh-content-head {
    padding-top: 26px;
    padding-bottom: 26px;
  }
} */
.sh-content-head__right-col {
  width: 100%;
  justify-content: space-between;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.sh-content-head__right-col .sh-content-head__control {
  width: 70px;
  margin-left: auto;
}
.sh-content-head__navigation {
  width: 100%;
  justify-content: space-between;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
/* @media only screen and (max-width: 1025px) {
  .sh-content-head__navigation {
    margin-bottom: 20px;
  }
} */
.sh-content-head .row [class^="col-"] {
  align-items: center;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.sh-content-head__control {
  width: 170px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
/* @media only screen and (max-width: 1400px) {
  .sh-content-head__right-col {
    margin-bottom: 10px;
  }
} */
.sh-content-head__right-col .sh-content-head__control {
  width: 70px;
  margin-left: auto;
}
.RecentPostIcon {
  margin-right: 15px;
}
.sh-menu {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  padding: 15px 20px 20px;
  background-color: #ffffff;
  border-radius: 6px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.sh-btn {
  justify-content: center;
  height: clamp(48px, 3vw, 100px);
  font-size: clamp(15px, 1.2vw, 20px);
  background-color: #754668;
  border-radius: 6px;
  border: solid 2px #754668;
  align-items: center;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.sh-btn i, .emoticon {
    font-size: clamp(12px, 1.4vw, 20px);
    margin-left: 10px;
    margin-right: 10px;
}
.sh-btn,
.sh-btn i {
  color: #ffffff;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.sh-btn:visited,
.sh-btn:active,
.sh-btn:link,
.sh-btn:visited i,
.sh-btn:active i,
.sh-btn:link i {
  color: #ffffff;
}
.sh-btn:hover {
  background-color: #ffffff;
}
.sh-btn:hover,
.sh-btn:hover i {
  color: #754668;
}

.sh-btn-social {
  border-radius: 50%;
  backface-visibility: hidden;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.sh-btn-social:hover {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.sh-btn-social i {
  font-size: 16px;
  color: #ffffff;
}
.sh-btn-social__facebook {
  background-color: #3b5998;
}
.sh-btn-social__twitter {
  background-color: #55acee;
}
.sh-btn-social__google {
  background-color: #dd4b39;
}
.sh-btn-social__pinterest {
  background-color: #cb2027;
}
.sh-btn-social__btc {
  background-color: #ff6600;
}
.sh-btn-social__tumblr {
  background-color: #32506d;
}
.sh-btn-social__vk {
  background-color: #45668e;
}
.sh-btn-social__reddit {
  background-color: #5f99cf;
}
.marginTop {
  margin-top: 3vh;
}
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 91px;
}
.ql-editor {
  min-height: 200px;
}

.parallaxHeight {
  position: relative;
  top: 40%;
  margin: 0px;
}

.parallaxText {
  color: white;
}
.sh-head-user__info {
  margin-top: -50px;
  margin-bottom: 30px;
}
.sh-head-user__info-head {
  margin-bottom: 30px;
  align-items: center;
  flex-wrap: wrap;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.sh-head-user__info-statistic .sh-btn-icon {
  margin-right: 15px;
}
.sh-head-user__info-statistic .sh-btn-icon i {
  margin-right: 10px;
}
.sh-head-user__info-statistic .sh-btn-icon span {
  color: #1e1633;
}
.sh-head-user__name {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 30px;
  font-size: 24px;
}
.sh-head-user__name span {
  margin-right: 15px;
}
.sh-head-user__name i img {
  width: 16px;
}
.sh-head-user__icons {
  margin-right: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.sh-head-user__icons i img {
  width: 34px;
}
.sh-head-user__icons i:not(:last-child) {
  margin-right: 16px;
}

.sh-head-user__btn-options {
  margin-top: 5px;
  margin-bottom: 5px;
}
.smallBorderBottom {
  border-bottom: solid 1px #e6e9f1;
}

.disabled {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 1px;
}
.margintop-80px {
  margin-top: -80px;
}
#datepickerdob button {
  padding: 0 !important;
}
.profileDescription {
  padding: 10px;
  justify-content: center;
  border-radius: 6px;
  color: white;
  background-color: #754668;
}
.profileDescription p {
  margin: 0px;
}
@media only screen and (max-width: 768px) {
  .marginleft-30 {
    margin-left: -30px;
  }
}
.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 1rem !important;
}
.filepond--root {
  font-family: "Rubik", "Roboto", sans-serif;
}
.filepond--credits {
  display: none;
}

.footer {
  margin-left: 2px;
  margin-right: 8px;
  color: inherit;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
}
.footer:hover {
  color: #754668;
}
.max-height-40vh {
  max-height: 40vh;
}
iframe {
  width: 100%;
  border-radius: 6px;
}
iframe[src*="yout"] {
  height: 43vh;
}
iframe[src*="tik"] {
  height: 724px;
}
iframe[src*="vimeo"] {
  height: 324px;
}
iframe[src*="face"] {
  height: 400px;
}
iframe[src*="plugins/post"] {
  height: 650px;
}
iframe[src*="insta"] {
  height: 464px;
  width: 400px;
  margin: 0 auto;
}
iframe[src*="twitter"] {
  width: 500px;
  height: 400px;
  margin: 0 auto;
}

.paymentProviderLogo {
  width: 150px !important;
  height: 150px !important;
}
.ql-toolbar.ql-snow {
    position: sticky;
 top: 70px;
 background: #fff;
 z-index: 100;
}