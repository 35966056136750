@font-face {
  font-family: "sharehub";
  src: url("./assets/icons/sharehub/fonts/sharehub.eot?b5q1ef");
  src: url("./assets/icons/sharehub/fonts/sharehub.eot?b5q1ef#iefix")
      format("embedded-opentype"),
    url("./assets/icons/sharehub/fonts/sharehub.ttf?b5q1ef") format("truetype"),
    url("./assets/icons/sharehub/fonts/sharehub.woff?b5q1ef") format("woff"),
    url("./assets/icons/sharehub/fonts/sharehub.svg?b5q1ef#sharehub")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "sharehub" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-align: center;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ArticleSingle_main .sh-section__image img[alt~="hentai"] {
  -webkit-filter: blur(20px); /* Safari 6.0 - 9.0 */
  filter: blur(20px);
}
.ArticleSingle_main .sh-section__content img[alt~="hentai"] {
  -webkit-filter: blur(20px); /* Safari 6.0 - 9.0 */
  filter: blur(20px);
}

.sh-section .sh-section__content img[alt~="hentai"] {
  -webkit-filter: blur(20px); /* Safari 6.0 - 9.0 */
  filter: blur(20px);
}
.ArticleSingle_main .sh-section__image img[alt~="porn-or-nudity"] {
  -webkit-filter: blur(20px); /* Safari 6.0 - 9.0 */
  filter: blur(20px);
}
.ArticleSingle_main .sh-section__content img[alt~="porn-or-nudity"] {
  -webkit-filter: blur(20px); /* Safari 6.0 - 9.0 */
  filter: blur(20px);
}

.sh-section .sh-section__content img[alt~="porn-or-nudity"] {
  -webkit-filter: blur(20px); /* Safari 6.0 - 9.0 */
  filter: blur(20px);
}

.icon-Add_Image:before {
  content: "\e900";
}
.icon-Angry .path1:before {
  content: "\e901";
  color: rgb(247, 224, 106);
}
.icon-Angry .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(247, 223, 105);
}
.icon-Angry .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(247, 222, 105);
}
.icon-Angry .path4:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(247, 221, 104);
}
.icon-Angry .path5:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(247, 220, 103);
}
.icon-Angry .path6:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(247, 219, 102);
}
.icon-Angry .path7:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(247, 218, 102);
}
.icon-Angry .path8:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(246, 216, 101);
}
.icon-Angry .path9:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(246, 215, 100);
}
.icon-Angry .path10:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(246, 214, 99);
}
.icon-Angry .path11:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(246, 213, 99);
}
.icon-Angry .path12:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(246, 212, 98);
}
.icon-Angry .path13:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(246, 211, 97);
}
.icon-Angry .path14:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(246, 211, 97);
}
.icon-Angry .path15:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(246, 209, 96);
}
.icon-Angry .path16:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(246, 208, 96);
}
.icon-Angry .path17:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(246, 207, 95);
}
.icon-Angry .path18:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(246, 205, 94);
}
.icon-Angry .path19:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(246, 204, 94);
}
.icon-Angry .path20:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(246, 202, 93);
}
.icon-Angry .path21:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(245, 201, 92);
}
.icon-Angry .path22:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(245, 200, 92);
}
.icon-Angry .path23:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(245, 198, 91);
}
.icon-Angry .path24:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(245, 197, 90);
}
.icon-Angry .path25:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(245, 196, 90);
}
.icon-Angry .path26:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(245, 194, 89);
}
.icon-Angry .path27:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(245, 193, 88);
}
.icon-Angry .path28:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(245, 192, 88);
}
.icon-Angry .path29:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(245, 190, 87);
}
.icon-Angry .path30:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(245, 189, 86);
}
.icon-Angry .path31:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(245, 188, 86);
}
.icon-Angry .path32:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(245, 186, 85);
}
.icon-Angry .path33:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(245, 185, 85);
}
.icon-Angry .path34:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(245, 184, 84);
}
.icon-Angry .path35:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(244, 182, 83);
}
.icon-Angry .path36:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(244, 181, 83);
}
.icon-Angry .path37:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(244, 179, 82);
}
.icon-Angry .path38:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(244, 178, 81);
}
.icon-Angry .path39:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(244, 177, 81);
}
.icon-Angry .path40:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(244, 175, 80);
}
.icon-Angry .path41:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(244, 174, 79);
}
.icon-Angry .path42:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(244, 173, 79);
}
.icon-Angry .path43:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(244, 171, 78);
}
.icon-Angry .path44:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(244, 170, 77);
}
.icon-Angry .path45:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(244, 169, 77);
}
.icon-Angry .path46:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(244, 167, 76);
}
.icon-Angry .path47:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(244, 166, 75);
}
.icon-Angry .path48:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(244, 165, 75);
}
.icon-Angry .path49:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(243, 163, 74);
}
.icon-Angry .path50:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(243, 162, 73);
}
.icon-Angry .path51:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(243, 161, 73);
}
.icon-Angry .path52:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(243, 159, 72);
}
.icon-Angry .path53:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(243, 158, 72);
}
.icon-Angry .path54:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(243, 157, 71);
}
.icon-Angry .path55:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(243, 155, 70);
}
.icon-Angry .path56:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(243, 154, 70);
}
.icon-Angry .path57:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(243, 152, 69);
}
.icon-Angry .path58:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(243, 151, 68);
}
.icon-Angry .path59:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(243, 150, 68);
}
.icon-Angry .path60:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(243, 148, 67);
}
.icon-Angry .path61:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(243, 147, 66);
}
.icon-Angry .path62:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(242, 145, 66);
}
.icon-Angry .path63:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(242, 143, 65);
}
.icon-Angry .path64:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(242, 142, 64);
}
.icon-Angry .path65:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(242, 141, 64);
}
.icon-Angry .path66:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(242, 139, 63);
}
.icon-Angry .path67:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(242, 138, 62);
}
.icon-Angry .path68:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(242, 137, 62);
}
.icon-Angry .path69:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(242, 135, 61);
}
.icon-Angry .path70:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(242, 134, 60);
}
.icon-Angry .path71:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(242, 133, 60);
}
.icon-Angry .path72:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(242, 131, 59);
}
.icon-Angry .path73:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(242, 130, 59);
}
.icon-Angry .path74:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(242, 128, 58);
}
.icon-Angry .path75:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(241, 127, 57);
}
.icon-Angry .path76:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(241, 126, 57);
}
.icon-Angry .path77:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(241, 124, 56);
}
.icon-Angry .path78:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(241, 123, 55);
}
.icon-Angry .path79:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(241, 122, 55);
}
.icon-Angry .path80:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(241, 120, 54);
}
.icon-Angry .path81:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(241, 119, 53);
}
.icon-Angry .path82:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(241, 118, 53);
}
.icon-Angry .path83:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(241, 116, 52);
}
.icon-Angry .path84:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(241, 115, 51);
}
.icon-Angry .path85:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(241, 114, 51);
}
.icon-Angry .path86:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(241, 112, 50);
}
.icon-Angry .path87:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(241, 111, 49);
}
.icon-Angry .path88:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(241, 110, 49);
}
.icon-Angry .path89:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(240, 108, 48);
}
.icon-Angry .path90:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(240, 107, 47);
}
.icon-Angry .path91:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(240, 105, 47);
}
.icon-Angry .path92:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(240, 104, 46);
}
.icon-Angry .path93:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(240, 103, 46);
}
.icon-Angry .path94:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(240, 101, 45);
}
.icon-Angry .path95:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(240, 100, 44);
}
.icon-Angry .path96:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(240, 99, 44);
}
.icon-Angry .path97:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(240, 97, 43);
}
.icon-Angry .path98:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(240, 96, 42);
}
.icon-Angry .path99:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(240, 95, 42);
}
.icon-Angry .path100:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(240, 93, 41);
}
.icon-Angry .path101:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(240, 92, 40);
}
.icon-Angry .path102:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(240, 91, 40);
}
.icon-Angry .path103:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(239, 89, 39);
}
.icon-Angry .path104:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(239, 88, 38);
}
.icon-Angry .path105:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(239, 87, 38);
}
.icon-Angry .path106:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(239, 85, 37);
}
.icon-Angry .path107:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(239, 84, 36);
}
.icon-Angry .path108:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(239, 83, 36);
}
.icon-Angry .path109:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(239, 81, 35);
}
.icon-Angry .path110:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Angry .path111:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Angry .path112:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Angry .path113:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Angry .path114:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Arrow_Down:before {
  content: "\e973";
}
.icon-Arrow_Left:before {
  content: "\e974";
}
.icon-Arrow_Right:before {
  content: "\e975";
}
.icon-Arrow_Up:before {
  content: "\e976";
}
.icon-Badge_01 .path1:before {
  content: "\e977";
  color: rgb(158, 162, 171);
}
.icon-Badge_01 .path2:before {
  content: "\e978";
  margin-left: -0.916015625em;
  color: rgb(27, 117, 187);
}
.icon-Badge_01 .path3:before {
  content: "\e979";
  margin-left: -0.916015625em;
  color: rgb(246, 146, 30);
}
.icon-Badge_01 .path4:before {
  content: "\e97a";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_01 .path5:before {
  content: "\e97b";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_01 .path6:before {
  content: "\e97c";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_01 .path7:before {
  content: "\e97d";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_01 .path8:before {
  content: "\e97e";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_01 .path9:before {
  content: "\e97f";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_01 .path10:before {
  content: "\e980";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_02 .path1:before {
  content: "\e981";
  color: rgb(158, 162, 171);
}
.icon-Badge_02 .path2:before {
  content: "\e982";
  margin-left: -0.916015625em;
  color: rgb(27, 117, 187);
}
.icon-Badge_02 .path3:before {
  content: "\e983";
  margin-left: -0.916015625em;
  color: rgb(246, 146, 30);
}
.icon-Badge_02 .path4:before {
  content: "\e984";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_02 .path5:before {
  content: "\e985";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_02 .path6:before {
  content: "\e986";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_02 .path7:before {
  content: "\e987";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_02 .path8:before {
  content: "\e988";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_02 .path9:before {
  content: "\e989";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_03 .path1:before {
  content: "\e98a";
  color: rgb(101, 45, 144);
}
.icon-Badge_03 .path2:before {
  content: "\e98b";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_03 .path3:before {
  content: "\e98c";
  margin-left: -0.916015625em;
  color: rgb(66, 37, 102);
}
.icon-Badge_03 .path4:before {
  content: "\e98d";
  margin-left: -0.916015625em;
  color: rgb(66, 37, 102);
}
.icon-Badge_03 .path5:before {
  content: "\e98e";
  margin-left: -0.916015625em;
  color: rgb(27, 117, 187);
}
.icon-Badge_03 .path6:before {
  content: "\e98f";
  margin-left: -0.916015625em;
  color: rgb(246, 146, 30);
}
.icon-Badge_03 .path7:before {
  content: "\e990";
  margin-left: -0.916015625em;
  color: rgb(75, 42, 117);
}
.icon-Badge_03 .path8:before {
  content: "\e991";
  margin-left: -0.916015625em;
  color: rgb(75, 42, 117);
}
.icon-Badge_03 .path9:before {
  content: "\e992";
  margin-left: -0.916015625em;
  color: rgb(75, 42, 117);
}
.icon-Badge_03 .path10:before {
  content: "\e993";
  margin-left: -0.916015625em;
  color: rgb(75, 42, 117);
}
.icon-Badge_03 .path11:before {
  content: "\e994";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_03 .path12:before {
  content: "\e995";
  margin-left: -0.916015625em;
  color: rgb(101, 45, 144);
}
.icon-Badge_03 .path13:before {
  content: "\e996";
  margin-left: -0.916015625em;
  color: rgb(158, 162, 171);
}
.icon-Blushing .path1:before {
  content: "\e997";
  color: rgb(248, 224, 105);
}
.icon-Blushing .path2:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Blushing .path3:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Blushing .path4:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Blushing .path5:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Blushing .path6:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Blushing .path7:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Calender:before {
  content: "\e99e";
}
.icon-Cancel:before {
  content: "\e99f";
}
.icon-Comment:before {
  content: "\e9a0";
}
.icon-Comment_Full:before {
  content: "\e9a1";
}
.icon-Cool .path1:before {
  content: "\e9a2";
  color: rgb(248, 224, 105);
}
.icon-Cool .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Cool .path3:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Cool .path4:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Cool .path5:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Crop:before {
  content: "\e9a7";
}
.icon-Dead .path1:before {
  content: "\e9a8";
  color: rgb(248, 224, 105);
}
.icon-Dead .path2:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Dead .path3:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(238, 64, 54);
}
.icon-Dead .path4:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(243, 125, 92);
}
.icon-Dead .path5:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Dead .path6:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Dead .path7:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Dead .path8:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Delete:before {
  content: "\e9b0";
}
.icon-Doubt .path1:before {
  content: "\e9b1";
  color: rgb(248, 224, 105);
}
.icon-Doubt .path2:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Doubt .path3:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Doubt .path4:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Doubt .path5:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Doubt .path6:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Doubt .path7:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Doubt .path8:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Doubt .path9:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Doubt .path10:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Doubt .path11:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Downvote:before {
  content: "\e9bc";
}
.icon-Email:before {
  content: "\e9bd";
}
.icon-Expand_all:before {
  content: "\e9be";
}
.icon-Favorite_Full:before {
  content: "\e9bf";
}
.icon-Favotire_empty:before {
  content: "\e9c0";
}
.icon-Flag:before {
  content: "\e9c1";
}
.icon-Followers:before {
  content: "\e9c2";
}
.icon-Following:before {
  content: "\e9c3";
}
.icon-Grid_View:before {
  content: "\e9c4";
}
.icon-Happy .path1:before {
  content: "\e9c5";
  color: rgb(248, 224, 105);
}
.icon-Happy .path2:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Happy .path3:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Happy .path4:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Happy .path5:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Happy .path6:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Image:before {
  content: "\e9cb";
}
.icon-Link:before {
  content: "\e9cc";
}
.icon-LOL .path1:before {
  content: "\e9cd";
  color: rgb(248, 224, 105);
}
.icon-LOL .path2:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-LOL .path3:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-LOL .path4:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-LOL .path5:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(238, 64, 54);
}
.icon-LOL .path6:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(238, 64, 54);
}
.icon-LOL .path7:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-LOL .path8:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Love .path1:before {
  content: "\e9d5";
  color: rgb(248, 224, 105);
}
.icon-Love .path2:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Love .path3:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Love .path4:before {
  content: "\e9d8";
  margin-left: -1em;
  color: rgb(239, 78, 79);
}
.icon-Love .path5:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(239, 78, 79);
}
.icon-Love .path6:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(238, 64, 54);
}
.icon-Love .path7:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(238, 64, 54);
}
.icon-Make_a_meme:before {
  content: "\e9dc";
}
.icon-No_words .path1:before {
  content: "\e9dd";
  color: rgb(248, 224, 105);
}
.icon-No_words .path2:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-No_words .path3:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-No_words .path4:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-No_words .path5:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-No_words .path6:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(250, 179, 110);
}
.icon-No_words .path7:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(250, 179, 110);
}
.icon-Notification:before {
  content: "\e9e4";
}
.icon-Options:before {
  content: "\e9e5";
}
.icon-Post:before {
  content: "\e9e6";
}
.icon-Randomize:before {
  content: "\e9e7";
}
.icon-Reply:before {
  content: "\e9e8";
}
.icon-Repost:before {
  content: "\e9e9";
}
.icon-Rotate_Left:before {
  content: "\e9ea";
}
.icon-Rotate_Right:before {
  content: "\e9eb";
}
.icon-Sad .path1:before {
  content: "\e9ec";
  color: rgb(248, 224, 105);
}
.icon-Sad .path2:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Sad .path3:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Sad .path4:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Sad .path5:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Sad .path6:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Sad .path7:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(93, 202, 235);
}
.icon-Scroll_Down .path1:before {
  content: "\e9f3";
  color: rgb(35, 31, 32);
}
.icon-Scroll_Down .path2:before {
  content: "\e9f4";
  margin-left: -0.83984375em;
  color: rgb(35, 31, 32);
  opacity: 0.25;
}
.icon-Scroll_Down .path3:before {
  content: "\e9f5";
  margin-left: -0.83984375em;
  color: rgb(35, 31, 32);
}
.icon-Scroll_Down .path4:before {
  content: "\e9f6";
  margin-left: -0.83984375em;
  color: rgb(35, 31, 32);
}
.icon-Scroll_Down .path5:before {
  content: "\e9f7";
  margin-left: -0.83984375em;
  color: rgb(35, 31, 32);
  opacity: 0.5;
}
.icon-Search:before {
  content: "\e9f8";
}
.icon-Share:before {
  content: "\e9f9";
}
.icon-Stat:before {
  content: "\e9fa";
}
.icon-Submit_Tick:before {
  content: "\e9fb";
}
.icon-Surprised .path1:before {
  content: "\e9fc";
  color: rgb(248, 224, 105);
}
.icon-Surprised .path2:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Surprised .path3:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Surprised .path4:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Surprised .path5:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Surprised .path6:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Tile_View:before {
  content: "\ea02";
}
.icon-Timeline_View:before {
  content: "\ea03";
}
.icon-Tongue_Out .path1:before {
  content: "\ea04";
  color: rgb(248, 224, 105);
}
.icon-Tongue_Out:before {
  content: "\ea04";
}
.icon-Tongue_Out .path2:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Tongue_Out .path3:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Tongue_Out .path4:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Tongue_Out .path5:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Tongue_Out .path6:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(35, 31, 32);
}
.icon-Tongue_Out .path7:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(238, 64, 54);
}
.icon-Tongue_Out .path8:before {
  content: "\ea0b";
  margin-left: -1em;
  color: rgb(243, 125, 92);
}
.icon-Upload_graphic .path1:before {
  content: "\ea0c";
  color: rgb(255, 255, 255);
}
.icon-Upload_graphic .path2:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(27, 117, 187);
}
.icon-Upload_graphic .path3:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(27, 117, 187);
}
.icon-Upload_graphic .path4:before {
  content: "\ea0f";
  margin-left: -1em;
  color: rgb(27, 117, 187);
}
.icon-Upload_graphic .path5:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Upload_graphic .path6:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(101, 45, 144);
}
.icon-Upload_graphic .path7:before {
  content: "\ea12";
  margin-left: -1em;
  color: rgb(101, 45, 144);
}
.icon-Upload_graphic .path8:before {
  content: "\ea13";
  margin-left: -1em;
  color: rgb(101, 45, 144);
}
.icon-Upload_graphic .path9:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(101, 45, 144);
}
.icon-Upload_graphic .path10:before {
  content: "\ea15";
  margin-left: -1em;
  color: rgb(101, 45, 144);
}
.icon-Upload_graphic .path11:before {
  content: "\ea16";
  margin-left: -1em;
  color: rgb(101, 45, 144);
}
.icon-Upload_graphic .path12:before {
  content: "\ea17";
  margin-left: -1em;
  color: rgb(101, 45, 144);
}
.icon-Upload_graphic .path13:before {
  content: "\ea18";
  margin-left: -1em;
  color: rgb(101, 45, 144);
}
.icon-Upload_graphic .path14:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(101, 45, 144);
}
.icon-Upload_graphic .path15:before {
  content: "\ea1a";
  margin-left: -1em;
  color: rgb(246, 146, 30);
}
.icon-Upload_graphic .path16:before {
  content: "\ea1b";
  margin-left: -1em;
  color: rgb(27, 117, 187);
}
.icon-Upload_Image:before {
  content: "\ea1c";
}
.icon-Upvote:before {
  content: "\ea1d";
}
.icon-Verified .path1:before {
  content: "\ea1e";
  color: rgb(52, 130, 226);
}
.icon-Verified .path2:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Video_to_GIF:before {
  content: "\ea20";
}
